import React, { useCallback, useEffect, useRef } from 'react';
import classes from 'classnames';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import s from './style.module.css';
import { getIsAccountClosure } from '@store/profile/selectors';
import { getMenuItems, FAQMenuItem } from '~/routes';

import { MenuItemProps } from '~/entities/headerMenu';
import * as S from './MobileMenu.styled';

const MenuItem = ({ link, index, onClose }: MenuItemProps) => {
  const Component = link.component;

  return (
    <S.MenuItem className={classes(s.menuItem)} link={link.path} key={index} onClick={onClose}>
      <div className={s.icon}>
        <Component />
      </div>

      {link.title}
    </S.MenuItem>
  );
};

interface Props {
  className: string;
  visible: boolean;
  onClose: () => void;
}

const MobileMenu = ({ className, visible, onClose }: Props) => {
  const mobileRoot = useRef<HTMLDivElement>(null);

  const closure = getIsAccountClosure();

  const menuLinks = getMenuItems(closure);

  const closeMenu = useCallback(() => {
    clearAllBodyScrollLocks();
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (visible && mobileRoot.current) {
      disableBodyScroll(mobileRoot.current);
      return closeMenu;
    }
  }, [closeMenu, visible]);

  return (
    <div className={classes(s.mobileMenu, className, visible && s.open)} ref={mobileRoot}>
      <div className={s.menu}>
        <div className={s.menu__container}>
          {menuLinks.map((link, i) => {
            return <MenuItem key={link.path} link={link} index={i} onClose={closeMenu} />;
          })}
          <MenuItem index={menuLinks.length} link={FAQMenuItem} onClose={closeMenu} />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
