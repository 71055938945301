import styled from 'styled-components';

import { Button } from '~/ui/kit/atoms/Button';

const BaseButton = styled(Button).attrs({
  type: 'link',
})`
  &.ant-btn {
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-family: TextaAltMedium, Arial, sans-serif;
    font-size: 24px;
    line-height: normal;
    text-decoration: none;
    position: relative;
    transition: 0.3s ease;
    background: none;
    border: none;
    color: var(--eko-header-fg-color);

    &:before {
      content: "";
      height: 0;
      width: 3px;
      left: 0;
      border-radius: 30px;
      background: var(--eko-header-fg-color);
      transition: 0.3s ease;
      position: absolute;

      @media (max-width: 992px) {
        display: none;
      }
    }

    &:hover {
      opacity: 1;
      color: var(--eko-header-fg-color);
      text-decoration: none;
    }
`;

export const MenuItem = styled(BaseButton).attrs({
  noDecoration: true,
})``;
