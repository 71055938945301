import React, { useEffect } from 'react';
import classes from 'classnames';
import { constVoid } from 'fp-ts/function';

export default function Loader({ onUnMount = constVoid, centralize = false, className = '', customStyle = {} }) {
  useEffect(() => {
    return onUnMount;
  }, [onUnMount]);
  const loader = (
    <div
      className={classes(className, 'spinner-border')}
      style={{
        borderWidth: '2px',
        ...customStyle,
      }}
      role="status"
    />
  );

  if (centralize) {
    return <div style={{ width: '100%', textAlign: 'center' }}>{loader}</div>;
  }

  return loader;
}
