import { useState } from 'react';
import { useSelector } from 'react-redux';

import s from './style.module.css';

import { useThemeContext } from '~/contexts/app';

import BasicContentNotification from '@components/layout/notification/variations/BasicContentNotification';

import emailAPI from '@api/endpoints/email';
import { VERIFY_EMAIL_MESSAGE } from '@constants/messages';
import { selectOrganization } from '@store/organization/selectors';

import { Organization } from '@models/organization';

import { setEmailVerificationClosed } from '@services/application';

interface Props {
  closable: boolean;
  onHideClick: () => void;
}

type Status = 'Sending...' | 'Successfully sent!' | 'Unable to send, try again later' | null;

const LinkMessage = () => {
  const [status, setStatus] = useState<Status>(null);

  const onResend = async () => {
    setStatus('Sending...');

    try {
      await emailAPI.resendVerification();
      setStatus('Successfully sent!');
    } catch (e) {
      setStatus('Unable to send, try again later');
    }
  };

  return (
    <>
      A link should be in your inbox, if not,
      <button className={s.verifyEmailNotificationContent__link} onClick={onResend} disabled={status === 'Sending...'}>
        click here
      </button>
      to resend the link. &nbsp;{status}
    </>
  );
};

const VerifyEmailNotification = ({ closable, onHideClick }: Props) => {
  const organization: Organization | null = useSelector(selectOrganization);
  const { displayName } = useThemeContext();
  let label = displayName;

  if (organization?.display_name) {
    label = organization.display_name;
  }

  const message = VERIFY_EMAIL_MESSAGE.replace('{orgName}', label);

  const handleHideClick = () => {
    setEmailVerificationClosed(true);
    onHideClick();
  };

  return (
    <BasicContentNotification closable={closable} onHideClick={handleHideClick}>
      <div className={s.verifyEmailNotificationContent}>
        <div className={s.verifyEmailNotificationContent__sideBar}></div>
        <div>
          {message}
          <br />
          <LinkMessage />
        </div>
      </div>
    </BasicContentNotification>
  );
};

export default VerifyEmailNotification;
