import React from 'react';
import classes from 'classnames';

import s from '../style.module.css';

import { useThemeContext } from '~/contexts/app';

import { NotificationConfig, NotificationTextContent } from '@models/application/notifications';
import BasicContentNotification from '@components/layout/notification/variations/BasicContentNotification';

interface Props {
  emoji?: string | null;
  text: string;
  closable: boolean;
  onHideClick: () => void;
}

const BasicNotification = ({ emoji, text, closable, onHideClick }: Props) => {
  const { showEmoji } = useThemeContext();

  return (
    <BasicContentNotification closable={closable} onHideClick={onHideClick}>
      <>
        {emoji && showEmoji && (
          <img className={classes(s.notification__emoji, s.notification__emoji_preface)} src={emoji} alt="" />
        )}
        {text}
      </>
    </BasicContentNotification>
  );
};

export default BasicNotification;

export const buildBasicNotification = (
  config: NotificationConfig,
  content: NotificationTextContent,
  icon: string | null | undefined,
  onHide: () => void,
) => <BasicNotification closable={config.closable} emoji={icon} text={content.text} onHideClick={onHide} />;
