import styled from 'styled-components';
import { Button } from '~/ui/kit/atoms/Button';
import { gaps } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

export const Container = styled(Button).attrs({
  type: 'primary',
  size: 'large',
  ghost: true,
})`
  font-weight: bold;

  &.ant-btn {
    padding: ${gaps.s}px ${gaps.xxl}px;

    ${media.sm`
      padding: ${gaps.m}px ${gaps.xxl + gaps.l}px;
  `}
  }
`;
