import notificationMap from '@components/layout/notification/map';

import { Notification as NotificationModel } from '@models/application/notifications';

interface Props {
  notification: NotificationModel;
}

const Notification = ({ notification }: Props) => {
  const found = notificationMap.find((item) => item.type === notification.type);
  return found ? found.component(notification) : null;
};

export default Notification;
