import { useHistory } from 'react-router-dom';

import s from './style.module.css';
import somethingWrongIcon from '@icons/gray/something-wrong.svg';

const ErrorSection = () => {
  const history = useHistory();

  return (
    <section className={s.errorPage}>
      <div className={s.errorPage__inner}>
        <img src={somethingWrongIcon} />

        <div className={s.errorPage__title}>Something went wrong</div>

        <div className={s.errorPage__description}>Failed to load content, please try again</div>

        <button onClick={() => history.go(0)}>Reload page</button>
      </div>
    </section>
  );
};

export default ErrorSection;
