import classes from 'classnames';

import s from './style.module.css';

import Notification from '@components/layout/notification/Notification';

import { Notifications as NotificationModels } from '@models/application/notifications';

interface Props {
  notifications: NotificationModels;
  fullScreen: boolean;
}

const Notifications = ({ notifications, fullScreen }: Props) => (
  <div className={classes(s.notifications, fullScreen && s.notifications_fullScreen)}>
    {notifications.map((notification) => (
      <Notification notification={notification} />
    ))}
  </div>
);

export default Notifications;
