import React, { ReactNode } from 'react';

import CaretIcon from '~/assets/images/caret.svg';
import styles from './SelectInput.module.css';

type BaseProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

type Props = BaseProps & {
  refvalue: BaseProps['ref'];
  options: ReadonlyArray<{
    readonly value: string;
    readonly name: ReactNode;
  }>;
};

export default function SelectDropdown({
  value,
  onChange,
  options,
  onBlur,
  style,
  onKeyUp,
  refvalue,
  onKeyDown,
  onFocus,
}: Props) {
  return (
    <div style={{ position: 'relative', ...style }}>
      <select
        ref={refvalue}
        onBlur={onBlur}
        tabIndex={-1}
        value={value}
        onChange={onChange}
        className={styles.selectDropdown}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      >
        <option value="">Choose</option>

        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      <img src={CaretIcon} alt="" className={styles.dropdownCaret} />
    </div>
  );
}
