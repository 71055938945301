import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ONBOARDING_ALPACA } from '@routes/names';

import * as S from './FundAccountBtn.styled';

type Props = {
  className?: string;
};

const FundAccountBtn: React.FC<Props> = ({ className }) => {
  const history = useHistory();

  const onClick = useCallback(() => history.push(ONBOARDING_ALPACA), [history]);

  return (
    <S.Container className={className} onClick={onClick}>
      Fund account
    </S.Container>
  );
};

export default FundAccountBtn;
