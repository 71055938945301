import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import classes from 'classnames';

import CrossPopoverIcon from '@assets/images/crossIconPopover.png';

import s from '@components/layout/notification/style.module.css';
import styles from '@pages/performance/style.module.css';

import { selectMenuType } from '@store/application/selectors';

interface Props {
  children: ReactElement | ReactElement[];
  closable: boolean;
  onHideClick: () => void;
}

const BasicContentNotification = ({ children, closable, onHideClick }: Props) => {
  const fullScreen = useSelector(selectMenuType) === 'vertical';

  return (
    <div className={classes(s.notification, s.notification_basic, fullScreen && s.notification_fullScreen)}>
      <div className={s.notification__content}>
        <div>{children}</div>

        {closable && (
          <button className={styles.popoverCloseBtn} onClick={onHideClick}>
            <img src={CrossPopoverIcon} alt="" />
          </button>
        )}
      </div>
    </div>
  );
};

export default BasicContentNotification;
